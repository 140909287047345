import "../styles/kategoriebanner.scss";
import * as CubeTrackingAK from "./tracking/cube/AktionskommunikationsTracking";
import { categorybanner, middle } from "./ENTDWindow";
import * as CubeTrackingAB from "./tracking/cube/AbTestTracking";
import * as GtmTrackingAB from "./tracking/gtm/AbTestTracking";
import "./CampModal";
import { CampModal } from "./CampModal";

class Kategoriebanner extends HTMLElement {
    private infoTextTrigger: HTMLElement | undefined | null;
    private infoTextModal: CampModal | undefined | null;
    private kampagneName: string | undefined | null;
    private observer: IntersectionObserver;
    private alreadyTracked: boolean;
    private internalEventsAbortController: AbortController | undefined;

    constructor() {
        super();
        this.observer = new IntersectionObserver(this.handleObservationEvent.bind(this), { threshold: 0.9 });
        this.alreadyTracked = false;
    }

    connectedCallback(): void {
        this.setupDOMEventHandling();
    }

    private handleObservationEvent(entries: IntersectionObserverEntry[]): void {
        entries.forEach((entry) => {
            if (entry.isIntersecting && !this.alreadyTracked) {
                CubeTrackingAK.trackView(this.kampagneName, middle, categorybanner);
                const el = this.querySelector<HTMLDivElement>('[data-select="banner"]');
                const abTestHeaderName = el?.getAttribute("data-abtest-name");
                const abTestHeaderValue = el?.getAttribute("data-abtest-value");
                CubeTrackingAB.trackView(abTestHeaderValue, abTestHeaderName);
                GtmTrackingAB.trackView(abTestHeaderValue, abTestHeaderName);
                this.alreadyTracked = true;
            }
        });
    }

    disconnectedCallback(): void {
        this.observer.disconnect();
        this.internalEventsAbortController?.abort();
    }

    private setupInfoLayer(signal: AbortSignal): void {
        this.infoTextModal = this.querySelector<CampModal>("#kategoriebanner-infotext");
        this.infoTextTrigger = this.querySelector<HTMLElement>("#kategoriebanner-infotext-trigger");
        this.infoTextTrigger?.addEventListener("click", this.handleInfoLayerOpenClick.bind(this), { signal });
    }

    private setupLinkClick(signal: AbortSignal): void {
        const link = this.querySelector<HTMLAnchorElement>('[data-select="link"]');
        if (this.kampagneName && link) {
            link.addEventListener(
                "click",
                (event) => {
                    const target = event.target as HTMLAnchorElement;
                    if (target) {
                        CubeTrackingAK.trackLinkClick(this.kampagneName, target.href, middle, categorybanner);
                    }
                },
                { signal },
            );
        }
    }

    private handleInfoLayerOpenClick(): void {
        CubeTrackingAK.trackDetailClick(this.kampagneName, middle, categorybanner);
        this.infoTextTrigger?.classList.add("clicked");
        this.infoTextModal?.classList.remove("display-none");
        this.infoTextModal?.show();
    }

    private setupDOMEventHandling(): void {
        this.internalEventsAbortController = new AbortController();
        this.kampagneName = this.querySelector<HTMLDivElement>('[data-select="banner"]')?.getAttribute("data-kampagne-name");
        const signal = this.internalEventsAbortController.signal;
        this.setupLinkClick(signal);
        this.setupInfoLayer(signal);
        this.observer.observe(this);
    }
}

customElements.define("entd-kategoriebanner", Kategoriebanner);
